/*
 * @Author: 宋攀展
 * @Date: 2024-08-07 14:06:14
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-08-26 17:10:52
 * @Description: Do not edit
 */
import { createApp } from "vue";
import "amfe-flexible";
import "./utils/rem";
import App from "./App.vue";
import Particles from "@tsparticles/vue3";
import { loadSlim } from "@tsparticles/slim";

// 引用element-plus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/theme-chalk/dark/css-vars.css";
// import zhCn from 'element-plus/dist/locale/zh-cn.min.js'
// 引用路由
import router from "./router";

// 引入全局样式
import "./assets/styles/global.scss";

const app = createApp(App);
// 引入所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(ElementPlus);
app.use(router);
app.use(Particles, {
  init: async (engine) => {
    await loadSlim(engine);
  },
});
app.mount("#app");
