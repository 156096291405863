/*
 * @Author: 宋攀展
 * @Date: 2024-08-07 16:04:30
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-08-19 10:43:21
 * @Description: Do not edit
 */
import { createRouter,createWebHashHistory } from "vue-router";

import { APP_NAME } from "../config/config";

import { staticRouter } from "./modules/index"

/**
 * 路由
 */
const routes = [
  ...staticRouter
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  strict: false,
  scrollBehavior: () => ({ left: 0, top: 0 })
});


router.beforeEach(async() => {
    
});

export default router;
