<!--
 * @Author: 宋攀展
 * @Date: 2024-08-22 10:30:46
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-08-26 19:48:37
 * @Description: Do not edit
-->
<template>
  <div class="main-container">
    <vue-particles
      id="tsparticles"
      @particles-loaded="particlesLoaded"
      :options="options"
    />
    <router-view></router-view>
  </div>
</template>
<script setup lang="ts">
const options = {
  background: {
    color: {
      value: "transparent",
    },
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      // onClick: {
      //   enable: true,
      //   mode: "push",
      // },
      // onHover: {
      //   enable: true,
      //   mode: "repulse",
      // },
    },
    modes: {
      bubble: {
        distance: 400,
        duration: 2,
        opacity: 0.8,
        size: 40,
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: "#56E4E4",
    },
    links: {
      color: "#0081FF",
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 2,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: "bounce",
      random: false,
      speed: 6,
      straight: false,
    },
    number: {
      density: {
        enable: true,
      },
      value: 50,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  detectRetina: true,
};
const particlesLoaded = async (container: any) => {
  // console.log("Particles container loaded", container);
};
</script>

<style lang="scss" scoped>
.main-container {
  background-image: url("./assets/images/home_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  // padding-top: 46px;
  box-sizing: border-box;
}
</style>
