/*
 * @Author: 宋攀展
 * @Date: 2024-08-07 15:46:31
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-08-19 09:25:46
 * @Description: Do not edit
 */
//  项目名称
export const APP_NAME = "统一展示平台";
// 首页地址（默认）
export const HOME_URL = "/home";

// 登录页地址（默认）
export const LOGIN_URL = "/login";

// 默认主题颜色
export const DEFAULT_PRIMARY:String = "#409EFF";

// Tabs（白名单地址，不需要添加到 tabs 的路由地址）
export const TABS_WHITE_LIST = ["/403", "/404", "/500", LOGIN_URL];

export const ROUTER_WHITE_LIST = ["/500"];