/*
 * @Author: 宋攀展
 * @Date: 2024-08-07 16:08:22
 * @LastEditors: Do not edit
 * @LastEditTime: 2024-08-19 10:44:34
 * @Description: Do not edit
 */
// ts需要使用RouteRecordRaw
// RouteRecordRaw 是 Vue Router 4.x 中新增的类型，用于定义路由配置。它是一个 TypeScript 类型

import { RouteRecordRaw } from "vue-router";

import { HOME_URL } from "../../config/config";

/**
 * static router 静态路由
 */

export const staticRouter: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: HOME_URL,
  },
  {
    path: HOME_URL,
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path:"/animation",
    name:'animation',
    component: () => import('@/views/animation.vue')
  }
];

/**
 * dynamic router 动态路由
 */

/**
 * error router 错误路由
 */

// export const errorRouter: Array<RouteRecordRaw> = [
//     {
//         path: '/:pathMatch(.*)*',
//         name: 'NotFound',
//         component: () => import(/* webpackChunkName: "error" */ '../../views/404.vue')
//     }
// ];
